<template>
  <el-dialog :title=" '上传治理函数'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <el-form label-width="140px">
            <el-form-item label="xml文件上传">
                <el-upload class="upload-demo" action="#"
                    :on-preview="handlePreviewXml"
                    :on-remove="handleRemoveXml"
                    :before-remove="beforeRemoveXml"
                    :on-change="fileChangeXml"
                    multiple :limit="1"
                    :on-exceed="handleExceedXml"
                    :file-list="xmlFileList">
                    <el-button size="samll" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">请上传xml文件</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="治理函数jar包上传">
              <el-upload class="upload-demo" action="#"
                :on-preview="handlePreview"
                :on-remove="handleRemove"
                :before-remove="beforeRemove"
                :on-change="fileChange"
                multiple :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList">
                <el-button size="samll" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">请上传治理函数jar包</div>
              </el-upload>
            </el-form-item>

            <el-form-item>
                <el-button @click="closeDialog()" size="medium">取消</el-button>
                <el-button @click="uploadHtml()" size="medium">确定</el-button>
            </el-form-item>
            
        </el-form>
        
  </div>
  </el-dialog>
</template>

<script>


export default {
  name:'dataQualityIframeUpload',
  components:{

  },
  data() {
    return {
      
      visible: false,
      xmlFileList:[],
      fileList: [],
      
    
    };
  },
  created(){
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    init() {
      
      this.xmlFileList=[];
      this.fileList=[];
      this.visible=true;
      
    },

    /**
     * 点击取消按钮
     */
    closeDialog(){
        this.xmlFileList=[];;
        this.fileList=[];
        this.visible=false;
    },

    /**
     * 点击确定按钮，上传页面
     */
    uploadHtml(){
        let formData=new FormData();
        /* this.fileList.forEach(file=>{
            formData.append('file',file.raw);
        }); */
        formData.append('xmlFile',this.xmlFileList[0].raw);
        formData.append('jarFile',this.fileList[0].raw);
        this.postRequest("/dataGovernMethod/dataGovernMethodUpload",formData).then(response=>{
            if(response&&response.status===200){
                this.$parent.initData();
                this.visible=false;
            }
        }).then(error=>{
            
        })
    },


    handleRemove(file, fileList) {
        console.log(file, fileList);
    },
    handleRemoveXml(xmlFile, xmlFileList) {
        console.log(xmlFile, xmlFileList);
    },


    handlePreview(file) {
        console.log(file);
    },
    handlePreviewXml(xmlFile) {
        console.log(xmlFile);
    },


    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    handleExceedXml(xmlFiles, xmlFileList) {
        this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${xmlFiles.length} 个文件，共选择了 ${xmlFiles.length + xmlFileList.length} 个文件`);
    },


    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
    beforeRemoveXml(xmlFile, xmlFileList) {
        return this.$confirm(`确定移除 ${ xmlFile.name }？`);
    },


      // 文件改变时
    fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    },
    fileChangeXml(xmlFile, xmlFileList) {
        this.xmlFileList=xmlFileList;
        this.xmlFileList.append(xmlFile);
    },

  },
};
</script>
<style scoped>
    .el-input{
        width: 260px;
    }
</style>
