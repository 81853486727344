<template>
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right" v-if="this.$router.currentRoute.path!='/home'">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>数据清洗标准</el-breadcrumb-item>
      <el-breadcrumb-item>自定治理函数</el-breadcrumb-item>
    </el-breadcrumb>

    <el-button style="margin-left:10px" type="primary" @click="openDataGovernMethodUpload()" size="medium ">上传自定数据治理函数</el-button>
    
    <!--<el-button @click="test">test</el-button>-->
    <br/>
    <!--			<span style="font-size: 12px;">{{this.currentCoal}}概述：</span>-->
    <!--			<div class="coalblock" v-for="item in options" :key="item.title">-->
    <!--				<span style="font-size: 12px; color:#9196a1 ;">{{item.title}}</span>-->
    <!--				<br/>-->
    <!--				<span style="font-size: 24px;">{{item.num}}</span>-->
    <!--			</div>-->
<!--    <coal-over-view></coal-over-view>-->

    <el-table
        :data="tableData"
        style="min-width: 100%"
        border
        v-loading="dataListLoading"
    >
      <el-table-column
          prop="id"
          label="治理函数id"
          header-align="center"
          align="center"
          min-width="5%"
      >
      </el-table-column>
      <el-table-column
          prop="methodChineseName"
          label="治理函数名称"
          header-align="center"
          align="center"
          min-width="5%" >
      </el-table-column>
      <el-table-column
          prop="jarFileName"
          label="所属jar包名称"
          header-align="center"
          align="center"
          min-width="5%" >
      </el-table-column>
      <el-table-column
          prop="createTime"
          label="创建时间"
          header-align="center"
          align="center"
          min-width="10%">
      </el-table-column>
      <el-table-column
          label="操作"
          header-align="center"
          align="center"
          min-width="10%">
        <template slot-scope="scope">
          <!-- <el-button
              type="text"
              size="small"
              @click="openPreviewIframe(scope.row.id)"
          >预览</el-button> -->
          
          <el-button
              type="text"
              class="warningButton"
              size="small"
              @click="handleDelete(scope.row.id,scope.row.isBasic)"
          >删除</el-button>
        </template>
      </el-table-column>

    </el-table>

    <!-- 分页区域 -->
    <div style="display: flex;justify-content: flex-end">
      <el-pagination
          background
          @current-change="currentChange"
          @size-change="sizeChange"
          :current-page="page"
          :page-size="size"
          layout="sizes, prev, pager, next, jumper, ->, total, slot"
          :total="total">
      </el-pagination>
    </div>
    <DataGovernMethodUpload v-if="dataGovernMethodUploadFlag" ref="dataGovernMethodUpload"></DataGovernMethodUpload>

  </div>

</template>

<script>
import DataGovernMethodUpload from "./dataGovernMethodUpload";//上传自定数据治理函数
export default {
  name: "dataQualityIframe",
  components: {
      DataGovernMethodUpload,
  },

  data() {
    return {
      name:'数据列表',
      currentCoal:"",
      tableData: [],
      page:1,  //当前第几页
      size:10, //当前每页页数
      total:100, //全部数据量

      dataGovernMethodUploadFlag:false,

      currOperator: '',
      currOperatorName: '',

      dataListLoading: false,
      overViewFlag: false,
    }
  },
  mounted(){
    //获取session中的user的id和name
    this.currOperator = JSON.parse(
        window.sessionStorage.getItem("user")
    ).userId;
    this.currOperatorName = JSON.parse(
        window.sessionStorage.getItem("user")
    ).username;

    this.initData();
  },
  methods: {
    initData(){
      this.dataListLoading = true;
      this.getRequest(
          "/dataGovernMethod/getDataGovernMethodInfoByPage/?page=" +
          this.page +
          "&size=" +
          this.size
      ).then((resp) => {
        if (resp) {
          this.tableData = resp.data.data;
          console.log(resp.data);
          this.total = resp.data.total;
          this.overViewFlag = true;
        }
        this.dataListLoading = false
      });
    },

    //打开预览页面
    openPreviewIframe(id){
        this.previewIframeFlag=true;
        this.$nextTick(() => {
        this.$refs.previewIframe.init(id);
      });
    },

    //打开自定数据标准上传页面
    openDataGovernMethodUpload(){
        this.dataGovernMethodUploadFlag=true;
        this.$nextTick(() => {
        this.$refs.dataGovernMethodUpload.init();
      });
    },
   
    currentChange(currentPage){
      this.page=currentPage;
      this.handleChange();
    },
    sizeChange(currentSize) {
      this.size = currentSize;
      this.handleChange();
    },
    //删除数据治理方法
    handleDelete(id,isBasic){
      this.$confirm('此操作将删除该治理方法, 是否继续?','提示',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if(isBasic==1){
          this.$message({
            type: 'error',
            message: '基础方法不可删除'
          }); 
          return;
        }
        this.getRequest(
          "/dataGovernMethod/deleteDataGovernMethod/?id=" +
          id
        ).then((resp) => {
          this.initData();
          if (resp) {
            if(resp && resp.code === 0){
              
            }else{
              
            }
          }
        });
      }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
      });
    }
  }
}
</script>

<style scoped>
.el-select {
  margin-top: 15px;
}
.el-button{
  margin-bottom: 15px;
}
</style>
